<template>
  <van-swipe :autoplay="3000" class="swipe">
    <van-swipe-item v-for="(image, index) in (local == 'en' ? images2 : images)" :key="index">
      <img :src="image"   />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import homeApi from "@/api/home";
export default {
  data() {
    return {
      local: localStorage.getItem("locale"),
      images: ['http://honghaigame.xyz/images/1.jpg','http://honghaigame.xyz/images/2.jpg','http://honghaigame.xyz/images/3.jpg'],
      images2: ['http://honghaigame.xyz/images/4.jpg','http://honghaigame.xyz/images/5.jpg','http://honghaigame.xyz/images/6.jpg'],
    };
  },
  computed: {},
  methods: {
    info() {
      homeApi
        .homeindex()
        .then((data) => {
          // data.data.banner.forEach(item => {
          //   if(!item.image.includes('http')){
          //     item.image = 'http://'+window.location.host+':5800'+item.image
          //   }
          // });
          // this.images = data.data.banner;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {},
  created() {
    this.info();
  },
};
</script>
<style lang="less" scoped>
.swipe {
  height: 5rem;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>